<template>
    <div class="content">

        <div class="back button" @click="$router.push({ name: 'Register' });">
            Wróć do rejestracji
        </div>

        <div class="content-inside">

            <h2>Regulamin Konkursu BE BRILLIANT WITH ALCON</h2>

            <h4 class="title">WSTĘP</h4>
            <p>Niniejszy Regulamin ustanawia się na podstawie art. 8 ust. 1 pkt. 1 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. z 2002 r., nr 144, poz. 1204 z p&oacute;źn. zm). Regulamin określa rodzaje i zakres usług świadczonych przez stronę www.alconacademy.pl przez Alcon Polska Sp. z o.o. z siedzibą w Warszawie, ul. Marynarska 15, 02-674 Warszawa, wpisaną do rejestru przedsiębiorc&oacute;w Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy pod numerem KRS 0000060964, NIP 527-109-31-05, REGON 011429418, wysokość kapitału zakładowego 750 000,00 PLN, drogą elektroniczną, zasady świadczenia tych usług, warunki zawierania i rozwiązywania um&oacute;w o świadczenie usług drogą elektroniczną oraz tryb postępowania reklamacyjnego. Regulamin zawiera też zasady dotyczące przetwarzania i zabezpieczenia danych zgodne z przepisami obowiązującego prawa.</p>
            <p>Prosimy dokładnie zapoznać się z niniejszymi dokumentem i warunkami zawartymi w Regulaminie</p>
            <h4>DEFINICJE</h4>
            <p>Na potrzeby niniejszego regulaminu, przyjmuje się następujące znaczenie poniższych pojęć:</p>
            <ol>
            <li><strong>Użytkownik</strong> &ndash; osoba fizyczna, kt&oacute;ra dokonała rejestracji poprzez podanie odpowiednich (wymaganych w formularzu rejestracyjnym) danych za pomocą środk&oacute;w informatycznych dostępnych w Serwisie, posiadający sw&oacute;j unikalny login zabezpieczony hasłem.</li>
            <li><strong>Usługa</strong> &ndash; polegającą na przeprowadzeniu konkursu BE BRILLIANT WITH ALCON w trakcie trwania &bdquo;IV Międzynarodowej Konferencji Naukowo-Szkoleniowej GlauCat 2021&rdquo;. Konkurs jest dostępny na stronie www.alconacademy.pl/konkurs</li>
            <li><strong>Konto</strong> - wydzielone dla danego Użytkownika w Serwisie strony internetowe, gdzie zbierane i prezentowane są informacje dotyczące tego Użytkownika. Konto Użytkownika może zawierać informacje o zebranych punktach, obejrzanych materiałach, o tym co warto obejrzeć w Serwisie.</li>
            <li><strong>Polityka Prywatności</strong> - odrębny dokument regulujący szczeg&oacute;łowo kwestie związane z przetwarzaniem danych osobowych i zasadami ochrony prywatności stosowanymi wobec Użytkownik&oacute;w Serwisu, znajdujący się pod adresem <a href="http://www.konkurs.alconacademy.pl/polityka-prywatnosci">konkurs.alconacademy.pl/polityka-prywatnosci</a></li>
            <li><strong>Regulamin </strong>&ndash; niniejszy regulamin, kt&oacute;ry zawiera w szczeg&oacute;lności prawa i obowiązki Użytkownik&oacute;w oraz zasady techniczne działania i funkcjonowania Serwisu dostępnego pod adresem <a href="http://www.konkurs.alconacademy.pl/regulamin">konkurs.alconacademy.pl/regulamin</a> oraz opisuje zasady konkursu.</li>
            <li><strong>Serwis</strong> &ndash; strona internetowa dostępna pod adresem www.alconacademia.pl</li>
            <li><strong>Usługodawca </strong>&ndash; Alcon Polska Sp. z o.o. z siedzibą w Warszawie, ul. Marynarska 15, 02-674 Warszawa, wpisaną do rejestru przedsiębiorc&oacute;w Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy pod numerem KRS 0000060964, NIP 527-109-31-05, REGON 011429418, wysokość kapitału zakładowego 750 000,00 PLN.</li>
            <li><strong>Konkurs</strong> &ndash; BE BRILLIANT WITH ALCON dostępny pod adresem <a href="http://www.konkurs.alconacademy.pl">konkurs.alconacademy.pl</a></li>
            <li><strong>Zadanie Konkursowe</strong> &ndash; zadania do wykonania w trakcie trwania Konkursu, dostępne w formie quiz&oacute;w i krzyż&oacute;wek. Zadania Konkursowe dotyczą produkt&oacute;w Usługodawcy.</li>
            <li><strong>Konferencja</strong> - IV Międzynarodowa Konferencja Naukowo-Szkoleniowa GlauCat 2021, kt&oacute;ra odbywa się w Mikołajkach, w dniach 27-28 sierpnia 2021 roku.</li>
            <li><strong>Administrator danych</strong> - oznacza osobę fizyczną lub prawną, organ publiczny, jednostkę lub inny podmiot, kt&oacute;ry samodzielnie lub wsp&oacute;lnie z innymi ustala cele i sposoby przetwarzania danych osobowych.</li>
            <li><strong>Dane osobowe</strong> - oznaczają informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej (&bdquo;osobie, kt&oacute;rej dane dotyczą&rdquo;); możliwa do zidentyfikowania osoba fizyczna to osoba, kt&oacute;rą można bezpośrednio lub pośrednio zidentyfikować, w szczeg&oacute;lności na podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub jeden bądź kilka szczeg&oacute;lnych czynnik&oacute;w określających fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej;</li>
            </ol>
            <h4>POSTANOWIENIA OG&Oacute;LNE</h4>
            <ol>
            <li>Za pośrednictwem Serwisu, Usługodawca świadczy Usługę polegającą na przeprowadzeniu Konkursu.</li>
            <li>Usługi, o kt&oacute;rych mowa w ust. 1 powyżej, świadczone są na rzecz Użytkownika nieodpłatnie.</li>
            <li>Do korzystania z dostępnych zasob&oacute;w za pośrednictwem Serwisu konieczne jest spełnienie następujących warunk&oacute;w technicznych przez komputer lub inne urządzenie Użytkownika:
            <ul>
            <li>dostęp do Internetu,</li>
            <li>standardowy system operacyjny,</li>
            <li>standardowa przeglądarka internetowa,</li>
            <li>posiadanie aktywnego adresu e-mail.</li>
            </ul>
            </li>
            <li>W razie korzystania przez Usługobiorcę ze sprzętu i oprogramowania, kt&oacute;re nie spełnia wymog&oacute;w technicznych określonych w niniejszym paragrafie, Usługodawca nie gwarantuje prawidłowości funkcjonowania Serwisu i zastrzega, że może to mieć negatywny wpływ na jakość świadczonych usług.</li>
            <li>Użytkownik nie może korzystać z Usługi dostępnej w Serwisie anonimowo, ani pod pseudonimem.</li>
            <li>W celu zapewnienia bezpieczeństwa Użytkownikowi i przekazu danych w związku z korzystaniem z Serwisu, Usługodawca podejmuje środki techniczne i organizacyjne odpowiednie do stopnia zagrożenia bezpieczeństwa świadczonych usług, w szczeg&oacute;lności środki służące zapobieganiu pozyskiwania i modyfikacji danych osobowych przez osoby nieuprawnione. Wszystkie informacje z tym związane znajdują się w Polityce Prywatności dostępnej w Serwisie pod adresem: <a href="http://www.konkurs.alconacademy.pl/polityka-prywatnosci">konkurs.alconacademy.pl/polityka-prywatnosci</a></li>
            <li>Usługodawca podejmuje działania w celu zapewnienia, w pełni poprawnego funkcjonowania Serwisu. Użytkownik powinien poinformować Usługodawcę o wszelkich nieprawidłowościach lub przerwach w funkcjonowaniu Serwisu.</li>
            <li>Wszelkie reklamacje związane z funkcjonowaniem Serwisu, Użytkownik może zgłaszać za pośrednictwem poczty elektronicznej na adres e-mail kontakt@alconacademy.pl lub pisemnie na adres Usługodawcy. W reklamacji Użytkownik powinien określić rodzaj i datę wystąpienia nieprawidłowości związanej z funkcjonowaniem Serwisu. Usługodawca będzie rozpatrywać wszelkie reklamacje w terminie do 14 dni od otrzymania reklamacji i poinformuje Użytkownika o jej rozstrzygnięciu na adres e-mail składającego reklamację.</li>
            <li>Usługodawca ma prawo usunąć lub ograniczyć Konto Użytkownika, jeśli używa go on w celach sprzecznych z prawem lub Regulaminem. Usługodawca powiadomi Użytkownika o powodach usunięcia lub ograniczenia Konta pocztą elektroniczną (na adres wskazany przez Użytkownika w formularzu rejestracyjnym).</li>
            <li>Usługodawca nie ponosi odpowiedzialności za problemy z funkcjonowaniem Serwisu występujące wskutek zdarzeń nie dających się przewidzieć lub kt&oacute;rym nie m&oacute;gł zapobiec.</li>
            <li>Usługodawca zastrzega sobie prawo do czasowego ograniczenia dostępności Usług, szczeg&oacute;lnie wtedy, gdy jest to niezbędne do przeprowadzenia koniecznych prac konserwacyjnych i serwisowych. O wszystkich planowanych przypadkach ograniczenia dostępności Usług z powod&oacute;w określonych w zdaniu pierwszym, Usługobiorcy będą informowani z odpowiednim wyprzedzeniem.</li>
            <li>Usługodawca nie ponosi odpowiedzialności za niedostarczone powiadomienia na adres e-mail Użytkownika , jeżeli oprogramowanie pocztowe lub serwer poczty Użytkownika zablokuje odbieranie wiadomości z serwera, z kt&oacute;rego Usługodawca wysyła powiadomienia do Użytkownika</li>
            </ol>
            <h4>ZASADY REJESTRACJI KONTA I OBOWIĄZKI UŻYTKOWNIK&Oacute;W</h4>
            <ol>
            <li>Uczestnictwo w Konkursie wymaga zarejestrowania się zgodnie z postanowieniami Regulaminu.</li>
            <li>Informacje dotyczące udziału w Konkursie będą dostępne podczas trwania Konferencji.</li>
            <li>Warunkiem rozpoczęcia świadczenia Usług przez Usługodawcę jest założenie Konta i wypełnienie przez Usługobiorcę formularza rejestracyjnego, umieszczonego w Serwisie, dokonanie akceptacji treści Regulaminu, Polityki Prywatności, oraz podanie danych osobowych oznaczonych jako obowiązkowe, zdefiniowanie nazwy Użytkownika oraz hasła dostępu. Po zarejestrowaniu się w Serwisie każdorazowe logowanie odbywa się przy użyciu danych podanych w formularzu rejestracyjnym.</li>
            <li>Rejestracja w Serwisie jest nieodpłatna.</li>
            <li>Użytkownik wysyłając formularz rejestracyjny oświadcza, że:</li>
            <li>podane w nim dane są kompletne i zgodne z istniejącym stanem faktycznym;</li>
            <li>podane przez niego dane nie naruszają jakichkolwiek praw os&oacute;b trzecich;</li>
            <li>zapoznał się z Regulaminem, Polityką Prywtaności i zobowiązuje się do przestrzegania tych dokument&oacute;w od chwili ich akceptacji. Akceptacja obu dokument&oacute;w następuje przez kliknięcie w &bdquo;checkbox&rdquo; przed założeniem Konta Użytkownikowi,</li>
            <li>nie będzie korzystał z Serwisu w spos&oacute;b zakł&oacute;cający jego funkcjonowanie, w szczeg&oacute;lności poprzez użycie określonego oprogramowania lub urządzeń,</li>
            <li>zobowiązuje się do niedostarczania i nieprzekazywania w szczeg&oacute;lności w ramach formularzy dostępnych w Serwisie treści zabronionych przez obowiązujące przepisy prawa, np. treści propagujących przemoc, zniesławiających lub naruszających dobra osobiste i prawa os&oacute;b trzecich, treści pornograficznych itp.</li>
            <li>Usługodawca w ramach funkcjonowania Serwisu udostępnia nieodpłatnie Użytkownikom Regulamin, Politykę Prywatności przed zawarciem umowy o świadczenie Usług. Usługodawca świadczy Usługi drogą elektroniczną zgodnie z niniejszym Regulaminem.</li>
            <li>Usługodawca może odm&oacute;wić utworzenia Konta, jeżeli Usługodawca poweźmie uzasadnioną, wiarygodną informację, że jest ona sprzeczna z prawem, dobrymi obyczajami, narusza dobra osobiste lub uzasadnione interesy Usługodawcy lub os&oacute;b trzecich. Osoba, kt&oacute;ra została pozbawiona prawa do korzystania z Serwisu, nie może dokonać powt&oacute;rnej rejestracji bez uprzedniej zgody Usługodawcy.</li>
            <li>Usługi świadczone przez Usługodawcę przeznaczone są wyłącznie na użytek własny Użytkownik&oacute;w i nie mogą być wykorzystywane w części lub całości do jakichkolwiek innych cel&oacute;w, w szczeg&oacute;lności o charakterze doradczym lub informacyjnym, rozrywkowym dla innych os&oacute;b, a także w celach por&oacute;wnawczych lub poglądowych.</li>
            <li>Usługi oferowane przez Serwis nie są świadczeniami zdrowotnymi w rozumieniu art. 3 ust. 1 Ustawy o działalności leczniczej. Nie są konsultacją lekarską ani poradą lekarską. Mają wyłącznie charakter konkursowy.</li>
            <li>Usługodawca zastrzega, iż koszty połączenia do sieci Internet oraz koszty związane z użytkowaniem rzeczy przy użyciu, kt&oacute;rych Użytkownik korzysta z usług Serwisu, nie obciążają Usługodawcy.</li>
            <li>Użytkownik jest zobowiązany do uaktualniania podanych przez siebie danych osobowych w przypadku ich zmiany.</li>
            <li>Użytkownik zobowiązany jest do zachowania w tajemnicy swojego loginu, hasła oraz do zabezpieczenia ich przed dostępem os&oacute;b trzecich.</li>
            <li>Użytkownik jest zobowiązany do korzystania z Serwisu wyłącznie zgodnie z jego przeznaczeniem.</li>
            </ol>
            <h4>WARUNKI UCZESTNICTWA W KONKURSIE</h4>
            <ol>
            <li>Konkurs przeprowadzany jest na obszarze Rzeczypospolitej Polskiej w trakcie trwania Konferencji.</li>
            <li>Konkurs nie jest grą losową w rozumieniu art. 2 ustawy o grach hazardowych z dnia 19.11.2009 r. (Dz. U. z 2009 r., Nr 201, poz. 1540 z p&oacute;źn. zm.) i nie podlega regułom zawartym w ww. ustawie i w rozporządzeniach wykonawczych do tej ustawy.</li>
            <li>Konkurs rozpoczyna się w dniu 27 sierpnia uruchomieniem Serwisu pod adresem www.alconacademy.pl /konkurs. Zgłoszenia do Konkursu będą przyjmowane wyłącznie w czasie trwania Konferencji, czyli w dniach 27-28 sierpnia 2021 roku. Konkurs kończy się o godzinie 24:00 w dniu zakończenia Konferencji. O wynikach Konkursu zwycięzcy zostaną poinformowani drogę elektroniczną</li>
            <li>Uczestnikiem Konkursu może być pełnoletnia osoba fizyczna, posiadająca pełną zdolność do czynności prawnych, będąca konsumentem w rozumieniu art. 221C., zamieszkała na terytorium Rzeczypospolitej Polskiej i posiadająca prawo wykonywania zawodu lekarza, posiadająca aktywny adres e -mail, kt&oacute;ra zaakceptowała Regulamin i Politykę Prywatności.</li>
            <li>W Konkursie nie mogą̨ brać udziału pracownicy Usługodawcy oraz inne osoby wsp&oacute;łpracujące przy organizacji Konkursu z Usługodawcą, jak r&oacute;wnież ich wstępni, zstępni, małżonkowie, rodzice małżonk&oacute;w, rodzeństwo oraz osoby pozostające z nimi w stosunku przysposobienia.</li>
            <li>Uczestnictwo w Konkursie jest dobrowolne</li>
            </ol>
            <h4>ZASADY KONKURSU</h4>
            <ol>
            <li>Użytkownik, aby wziąć udział w Konkursie musi zalogować się w trakcie trwania Konferencji, na moduł konkursowy dostępny w Serwisie pod adresem <a href="http://www.konkurs.alconacademy.pl">konkurs.alconacademy.pl</a>, w kt&oacute;rym znajdują się Zadania Konkursowe do rozwiązania.</li>
            <li>Zadania Konkursowe, o kt&oacute;rych mowa w ust. 1 niniejszego paragrafu będą pojawiały się w trakcie trwania Konkursu w Serwisie w określonych godzinach: 27.08.2021 pierwszy quiz o godzinie 11.00, krzyż&oacute;wka o godzinie 15.00. 28.08.2021 quiz o godzinie 10.00.</li>
            <li>Zadania Konkursowe będą rozwiązywane na czas i mogą być rozwiązane przez Użytkownika tylko raz. Liczy się czas wykonania zadania i poprawność odpowiedzi.</li>
            <li>W przypadku jeśli Użytkownik nie zaloguje się do Serwisu pierwszego dnia trwania Konferencji, a logowania dokona drugiego dnia Konferencji po godzinie 11, Użytkownik po zalogowaniu do Serwisu zobaczy wszystkie zadania (łącznie 3 zadania).</li>
            <li>Pytania w Zadaniu Konkursowym typu quiz są jednokrotnego wyboru. Poszczeg&oacute;lne pytania będą wyświetlane Użytkownikowi na kolejnych planszach. W momencie udzielenia niepoprawnej odpowiedzi, wyświetlony zostaje komunikat o nieudzieleniu poprawnej odpowiedzi przez Użytkownika i pozostaje on na tym samym pytaniu, aż do momentu udzielenia poprawnej odpowiedzi.</li>
            <li>Za każdy błąd popełniony w Zadaniu Konkursowym typu quiz, zostanie doliczone do czasu og&oacute;lnego 10 sekund, nawet w przypadku, gdy niepoprawna odpowiedz na to samo pytanie zostanie udzielona po raz kolejny.</li>
            <li>Zadanie konkursowe w postaci krzyż&oacute;wki składa się z 10 pytań. W przypadku udzielenia trzech błędnych odpowiedzi w krzyż&oacute;wce błędne hasła zostają wykasowane oraz ich obszar zostaje podświetlany na czerwono. W przypadku udzielenia trzech błędnych odpowiedzi do czasu zostaje doliczone dodatkowe 10 sek.</li>
            <li>Uczestnictwo w Konkursie jest nieodpłatne.</li>
            </ol>
            <h4>NADZ&Oacute;R NAD KONKURSEM, WYNIKI</h4>
            <ol>
            <li>Nadz&oacute;r na prawidłowym przebiegiem Konkursu sprawuje Usługodawca.</li>
            <li>Zwycięzcy Konkursu zostaną poinformowani o wygranej, w tym otrzymają numery e &ndash; kart do realizacji, na podany podczas rejestracji do Konkursu adres e-mail.</li>
            </ol>
            <h4>&nbsp;NAGRODY</h4>
            <ol>
            <li>Pierwszych 50 (słownie: pięćdziesięciu) Użytkownik&oacute;w, kt&oacute;rzy poprawnie rozwiążą wszystkie Zadania konkursowe otrzyma e - karty na zakup publikacji medycznych o wartości 100 PLN (słownie: sto złotych), do realizacji na stronie www.empik.com.</li>
            <li>Użytkownicy, zwycięzcy Konkursu nie są uprawnieni do przeniesienia prawa do otrzymania nagrody na osoby trzecie.</li>
            <li>Nagroda nie podlega wymianie na ekwiwalent pieniężny lub inną nagrodę ani obrotowi handlowemu.</li>
            <li>Przekazanie nagrody nastąpi w terminie do 15 września 2021 roku.</li>
            </ol>
            <h4>DANE OSOBOWE I PLIKI COOKIES</h4>
            <ol>
            <li>Administratorem danych osobowych Użytkownika jest Usługodawca.</li>
            <li>Dane osobowe Użytkownika, przetwarzane są w następujących celach i w oparciu o następujące podstawy prawne:</li>
            </ol>
            <ul>
            <li>rejestracja i utrzymywanie konta użytkownika &ndash; art. 6 ust. 1 lit. b RODO,</li>
            <li>obsługa reklamacji lub odstąpienia od umowy &ndash; art. 6 ust. 1 lit. f RODO,</li>
            <li>obsługa korespondencji &ndash; art. 6 ust. 1 lit. f RODO,</li>
            <li>tworzenie archiwum na potrzeby ewentualnej konieczności obrony, ustalenia lub dochodzenia roszczeń&ndash; art. 6 ust. 1 lit. f RODO,</li>
            <li>marketing własny &ndash; art. 6 ust. 1 lit. f RODO,</li>
            <li>wysyłka nagrody - art. 6 ust. 1 lit. b RODO,</li>
            </ul>
            <ol start="3">
            <li>Odbiorcami danych osobowych Użytkownik&oacute;w są: urzędy skarbowe w przypadku jeśli przepisy prawa nakładają obowiązek odprowadzenia podatku od nagrody , kancelaria prawna, firma świadcząca działalność marketingową, hostingodawca, firma informatyczna utrzymująca Serwis.</li>
            <li>Dane osobowe Użytkownik&oacute;w są przechowywane w bazie wyłącznie przez czas upływu terminu przedawnienia roszczeń z umowy zawartej z Usługodawcą oraz przez czas trwania konkursu. Szczeg&oacute;łowe informacje nt. zasad przechowywania danych osobowych można znaleźć w naszej Polityce Prywatności.</li>
            <li>Dane osobowe Użytkownik&oacute;w będą przekazywane do państw trzecich na zasadach określonych w Polityce Prywatności.</li>
            <li>Uprawnienia Użytkownika związane z przetwarzaniem danych osobowych tj. prawo do żądania od Usługodawcy dostępu do danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo wniesienia sprzeciwu wobec przetwarzania, prawo do przenoszenia danych, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych zostały szczeg&oacute;łowo om&oacute;wione w Polityce Prywatności.</li>
            <li>Podanie danych osobowych przez Użytkownika jest dobrowolne, ale niezbędne by skontaktować się z Usługodawcą, założyć konto Użytkownika, zawrzeć umowę, uczestniczyć w Konkursie.</li>
            <li>Odpowiedzialnym za wszelkie działania związane z posługiwaniem się hasłem do danego konta jest Użytkownik tego Konta. Użytkownik jest zobowiązany do utrzymania w ścisłej tajemnicy hasła koniecznego do zalogowania się na Konto. Zakazane jest udostępnianie komukolwiek hasła do Konta.</li>
            <li>Użytkownik posiada prawo do cofnięcia udzielonej zgody na przetwarzanie danych osobowych w dowolnym momencie.Szczeg&oacute;ły związane z danymi osobowymi oraz plikami cookies opisane zostały w Polityce Prywatności dostępnej pod adresem <a href="http://www.konkurs.alconacademy.pl/polityka-prywatnosci">konkurs.alconacademy.pl/polityka-prywatnosci</a></li>
            </ol>
            <h4>Pozostałe postanowienia</h4>
            <ol>
            <li>Usługodawca zastrzega sobie możliwość wprowadzania zmian w Regulaminie.</li>
            <li>Niniejszy Regulamin obowiązuje od dnia 08.2021.</li>
            </ol>
            <p>&nbsp;</p>


        </div>
    </div>
</template>

<script>
    export default {
        name: 'PolitykaPrywatnosci'
    }
</script>
